<template>
	<div class="content-wrapper bg-white">
		<header class="d-flex align-items-center header pt-3 px-2 ml-2 justify-content-between">
			<div class="d-flex fa-lg align-items-center">
				<i class="mb-2 font-color-custom text-muted fas fa-flask " />
				<h3 class="text-muted ml-2">Planilha Admin</h3>
			</div>
			
			<div v-if="files.length === 0" class="d-flex align-items-center pr-5">
				<span class="pr-2">{{ this.loadingData ? 'Gerando arquivo' : 'Gerar arquivo' }}</span>
				<button title="Gerar arquivo" class="btn btn-outline-primary btn-md" :disabled="files.length > 0" @click="generateFile()">
					<span v-if="loadingData" class="spinner-grow info" style="width: 1.2rem; height: 1.2rem;"></span>
					<span v-else>
						<i class="fa fa-file"></i>
					</span>
				</button>
			</div>
		</header>

		<main class="mt-3 d-flex flex-column">
			<div class="card-body table-responsive p-0">
				<table class="table table-hover table-stripped table-head-fixed">
					<thead>
						<tr>
							<th>Nome do arquivo</th>
							<th class="justify-content-center">Ações</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(file, index) in files" :key="index">
							<td>{{ file }}</td>
							<td>
								<button title="Baixar" class="btn btn-outline-primary btn-sm ml-1" @click="downloadItem(file)">
									<i class="fa fa-download"></i>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loadingData: false,
			files: []
		};
	},

	methods: {
		getFiles() {
			this.loadingData = true;
			this.$business_management_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`;
			this.$business_management_api
				.get('export/files')
				.then((res) => {
					this.files = res.data;
				})
				.catch(() => {
					this.$toasted.global.defaultError({ msg: 'Erro ao buscar arquivos.' });
				})
				.finally(() => this.loadingData = false);
		},

		downloadItem(file) {
			this.$business_management_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`;
			this.$business_management_api
				.get(`export/download/${file}`, { responseType: 'blob' })
				.then(response => {
					const blob = new Blob([response.data], { type: 'application/xlsx' })
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = file
					link.click()
					URL.revokeObjectURL(link.href);
				}).catch(console.error)
		},

		generateFile() {
			this.loadingData = true;
			this.$business_management_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`;
			this.$business_management_api
				.get('export/generate-file')
				.then((res) => {
					this.$toasted.global.defaultSuccess({ msg: 'O arquivo está sendo gerado, por favor aguarde!' }, { duration: 3000 });
					this.getFiles()
				})
				.catch(() => {
					this.$toasted.global.defaultError({ msg: 'Erro ao gerar arquivos.' });
				})
				.finally(() => this.loadingData = false);
		}
	},

	mounted() {
		this.getFiles();
	},
};
</script>

<style scoped>
.btn-pagination {
	border-radius: 0 !important;
}
</style>
